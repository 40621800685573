import React from "react";
import ReactGA from "react-ga";
import { BrowserRouter, Switch } from "react-router-dom";
import withLayout from "../components/layout/Layout";
import { allFlattenRoutes as routes } from "./RouteIndex";
import env from "../config";

const Routes = () => {
  const gaDebug = process.env.NODE_ENV === "development";
  if (gaDebug === false) {
    ReactGA.initialize([
      {
        trackingId: env.ga_id,
      },
    ]);
  }

  return (
    <BrowserRouter>
      <Switch>
        {routes.map((route, index) => {
          if (gaDebug === false) {
            ReactGA.send({
              hitType: "pageview",
              page: window.location.pathname + window.location.search,
              title: route.name,
            });
          }
          return (
            !route.children && (
              <route.route
                key={index}
                path={route.path}
                exact={route.exact}
                component={withLayout((props) => {
                  document.title = route.name;
                  return <route.component {...props} />;
                })}
              />
            )
          );
        })}
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
